import styled from "styled-components";
import { CloydLogo } from "../../assets/icons";
import { device } from "../../assets/themes/breakpoints";
import { CircleLink } from "../../ui";
import { Links } from "./components";
import { LINKS_DATA } from "./cosntants";

export const Footer = () => {
  return (
    <Wrapper>
      <Content>
        <LogoWrapper>
          <CloydLogo />
          <CircleLinksWrapper>
            <CircleLink type="LinkedIn" />
            <CircleLink type="YouTube" />
            <CircleLink type="Facebook" />
            <CircleLink type="Instagram" />
          </CircleLinksWrapper>
        </LogoWrapper>
        {LINKS_DATA.map((linkGroup, index) => (
          <Links key={index} links={linkGroup.links} title={linkGroup.title} />
        ))}
      </Content>
      <UnderLine />
      <CopyRight>© 2023, UAB Cloyd IXR, Inc. All rights reserved.</CopyRight>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  position: relative;
  margin-top: 181px;
  margin-bottom: 50px;

  width: 100%;
  max-width: 1620px;
  padding: 23px 90px;

  @media ${device.laptopL} {
    margin-top: 70px;
    padding: 23px 40px;
    margin-bottom: 0;
  }

  @media ${device.mobileL} {
    padding: 23px 16px;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.tabletL} {
    flex-direction: column;
  }
`;

const LogoWrapper = styled.div`
  margin-right: 15%;

  @media ${device.tabletL} {
    margin-bottom: 10px;
  }
`;

const UnderLine = styled.div`
  width: 100%;
  height: 1px;
  background: black;
  opacity: 0.1;
  margin-top: 44px;

  @media ${device.tabletL} {
    display: none;
  }
`;

const CopyRight = styled.p`
  margin-top: 46px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #505050;

  @media ${device.tabletL} {
    margin-top: 16px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
    line-height: 16px;
  }
`;

const CircleLinksWrapper = styled.div`
  display: flex;
  column-gap: 14px;
  margin-top: 34px;
`;
