import { NavLink } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { device } from "../../../assets/themes/breakpoints";
import { RoutePaths } from "../../../interfaces/routesPaths";
import { CircleLink, LinkWithArrow } from "../../../ui";
import { SubMenu } from "./SubMenu";

const LinksList: (
  | {
      title: string;
      link: string;
      subMenu?: undefined;
    }
  | {
      title: string;
      subMenu: {
        title: string;
        link: string;
      }[];
      link?: undefined;
    }
)[] = [
  {
    title: "Home",
    link: RoutePaths.BASE_PATH.path,
  },
  {
    title: "About",
    link: RoutePaths.ABOUT_CLOYD.path,
  },
  {
    title: "Solutions",
    subMenu: [
      {
        title: "3D Manuals",
        link: RoutePaths.MANUALS.path,
      },
      {
        title: "3D Immersive training",
        link: RoutePaths.IMMERSIVE_TRAINING.path,
      },
      {
        title: "3D Collaboration",
        link: RoutePaths.IMMERSIVE_COLLABORATION.path,
      },
    ],
  },
  {
    title: "Products",
    subMenu: [
      {
        title: "Cloyd IXR",
        link: RoutePaths.CLOYD_IXR.path,
      },
      {
        title: "IXR Hubs",
        link: RoutePaths.IXR_HUBS.path,
      },
    ],
  },
  {
    title: "Industries",
    link: RoutePaths.INDUSTRIES.path,
  },
  {
    title: "Pricing",
    link: RoutePaths.EMPTY.path,
  },
  {
    title: "Downloads",
    link: RoutePaths.DOWNLOAD.path,
  },
  {
    title: "Contacts",
    link: RoutePaths.CONTACT_US.path,
  },
];

interface LinksProps {
  isMenuOpen: boolean;
  closeMenu: () => void;
}

export const Links = ({ isMenuOpen, closeMenu }: LinksProps) => {
  return (
    <List $isMenuOpen={isMenuOpen}>
      {LinksList.map((item, index) => (
        <Li key={index}>
          {item?.subMenu ? (
            <SubMenu
              title={item.title}
              menu={item.subMenu}
              animationDelay={0.5 + index * 0.04}
              closeHeaderMenu={closeMenu}
            />
          ) : item.link === RoutePaths.EMPTY.path ? (
            <ComingSoonLink $animationDelay={0.5 + index * 0.04}>
              {item.title}
            </ComingSoonLink>
          ) : (
            <NavigationLink
              to={item.link}
              $animationDelay={0.5 + index * 0.04}
              onClick={closeMenu}
            >
              {item.title}
            </NavigationLink>
          )}
        </Li>
      ))}
      <Footer>
        <CircleLinksWrapper>
          <CircleLink type="LinkedIn" />
          <CircleLink type="YouTube" />
          <CircleLink type="Facebook" />
          <CircleLink type="Instagram" />
        </CircleLinksWrapper>
        <Underline />
        <LinkWithArrow href={RoutePaths.DOWNLOAD.path} text="Download App!" />
      </Footer>
    </List>
  );
};

const List = styled.ul<{ $isMenuOpen: boolean }>`
  display: flex;
  column-gap: 36px;

  @media (max-width: 1290px) {
    column-gap: 30px;
  }

  @media ${device.laptopL} {
    top: 76px;
    left: ${({ $isMenuOpen }) => ($isMenuOpen ? "0" : "-100vw")};
    width: 100vw;
    height: calc(100vh - 76px);
    position: absolute;
    flex-direction: column;
    background-color: white;
    row-gap: 24px;
    padding: 23px 40px;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: left 0.3s ease-in-out;
  }

  @media ${device.mobileL} {
    top: 64px;
    height: calc(100vh - 64px);
    padding: 23px 16px;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Li = styled.li`
  display: flex;
`;

const ComingSoonLink = styled.p`
  display: flex;
  position: relative;
  z-index: 2;
  font-size: 16px;
  font-weight: "normal";
  line-height: 21px;
  user-select: none;
  cursor: not-allowed;
  animation: ${fadeIn} 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    ${({ $animationDelay }) => $animationDelay}s both;

  @media ${device.laptopL} {
    font-size: 20px;
    line-height: 26px;
  }

  color: #1d2d44;

  &:after {
    content: "Soon";
    position: absolute;
    top: 0px;
    right: -30px;
    padding: 2px 5px;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 2px;
    font-size: 8px;
    line-height: 8px;
  }
`;

const NavigationLink = styled(NavLink)<{
  $animationDelay: number;
}>`
  display: flex;
  position: relative;
  z-index: 2;
  font-size: 16px;
  font-weight: "normal";
  line-height: 21px;
  user-select: none;
  cursor: pointer;
  animation: ${fadeIn} 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    ${({ $animationDelay }) => $animationDelay}s both;

  &:hover {
    letter-spacing: -0.4px;
    font-weight: bold;
  }

  @media ${device.laptopL} {
    font-size: 20px;
    line-height: 26px;
  }

  color: #1d2d44;
`;

const CircleLinksWrapper = styled.div`
  display: flex;
  column-gap: 14px;
  margin-top: 48px;
`;

const Footer = styled.div`
  position: relative;
  z-index: 2;
  display: none;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-end;

  @media ${device.laptopL} {
    display: flex;
  }
`;

const Underline = styled.div`
  position: relative;
  margin-top: 30px;
  margin-bottom: 16px;
  width: calc(180px + 100%);
  left: -90px;
  height: 1px;
  background-color: black;
  opacity: 0.1;
`;
