import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";
import { LinkWithArrow, ModalWindow } from "../../../../../ui";

import gsap from "gsap";
import { relative } from "path";

const MANUAL_LINK =
  "https://dev.cloyd.inlu.net/headless-viewer/43e4504f-786f-493d-8e4b-0726e2d08a98?manual=c2s0";

export const LeftSide = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const linkRef = useRef<HTMLDivElement>(null);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const title = titleRef.current;
    const text = textRef.current;
    const link = linkRef.current;

    if (!wrapper || !title || !text || !link) return;

    gsap.fromTo(
      title,
      {
        opacity: 0,
        y: 25,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        delay: 0,
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
        },
      }
    );

    gsap.fromTo(
      text,
      {
        opacity: 0,
        y: 25,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        delay: 0.3,
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
        },
      }
    );

    gsap.fromTo(
      link,
      {
        opacity: 0,
        y: 25,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        delay: 0.6,
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
        },
      }
    );

    return () => {
      gsap.killTweensOf([title, text, link]);
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <Title ref={titleRef}>
        <Highlight> Why are</Highlight> 3D tutorials so important?
      </Title>
      <Text ref={textRef}>
        It makes it easy to understand the content, reducing the time spent
        reading conventional printed manuals.
      </Text>
      <div ref={linkRef}>
        <LinkWithArrowStyled
          text={"Try Now!"}
          linkType="dark"
          onClick={handleOpenModal}
        />
      </div>
      <ModalWindowStyled
        contentStyle={{
          padding: 0,
          borderRadius: 15,
          overflow: "hidden",
          position: "relative",
        }}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      >
        <ModelWrapper>
          <FakeCloseButton onClick={handleCloseModal} />
          <iframe
            title="3D Tutorial"
            src={MANUAL_LINK}
            width="100%"
            height="100%"
            allow="autoplay"
          />
        </ModelWrapper>
      </ModalWindowStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  max-width: 596px;
  align-items: flex-start;
`;

const Title = styled.h2`
  ${({ theme }) => theme.typography.title_2_bold};

  @media ${device.tablet} {
    ${({ theme }) => theme.typography.title_2_bold_tablet};
  }

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.title_2_bold_mobile};
  }
`;

const Highlight = styled.span`
  opacity: 0.5;
`;

const Text = styled.p`
  margin-top: 29px;
  margin-bottom: 44px;
  max-width: 710px;

  ${({ theme }) => theme.typography.paragraph_1_regular};

  @media ${device.mobileL} {
    margin-top: 15px;
    ${({ theme }) => theme.typography.paragraph_1_regular_mobile};
  }
`;

const LinkWithArrowStyled = styled(LinkWithArrow)`
  @media ${device.laptop} {
    display: none;
  }
`;

const ModalWindowStyled = styled(ModalWindow)`
  padding: 0;
`;

const ModelWrapper = styled.div`
  max-width: 100%;
  max-height: 100%;
  width: 900px;
  height: 449px;
  border-radius: 15px;
  overflow: hidden;
`;

const FakeCloseButton = styled.button`
  position: absolute;
  top: 40px;
  left: 40px;
  width: 50px;
  height: 50px;
`;
